@use 'styles/breakpoints';
@use 'styles/spacing';

.squareLogoImage {
  height: auto;
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 2rem;
  padding-right: 2rem;
}

.rectangleLogoimage {
  width: auto;
  height: 32px;
  position: absolute;
  bottom: spacing.$spacing-16;
  left: spacing.$spacing-12;
}
