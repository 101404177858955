@use './breakpoints';

$card-width-mobile: 39%;

@mixin cardWidthVertical {
  width: $card-width-mobile;
  @include breakpoints.media(tablet) {
    width: 31%;
  }
  @include breakpoints.media(desktop-small) {
    width: 27%;
  }
  @include breakpoints.media(desktop-xl) {
    width: 34%;
  }
}

@mixin cardWidthVerticalWithAspectRatio_1_1 {
  width: 4.5625rem;

  @include breakpoints.media(mobile-landscape) {
    width: 7.5rem;
  }

  @include breakpoints.media(tablet) {
    width: 5rem;
  }

  @include breakpoints.media(tablet-landscape) {
    width: 7.125rem;
  }

  @include breakpoints.media(desktop-small) {
    width: 6.125rem;
  }

  @include breakpoints.media(desktop) {
    width: 8.8125rem;
  }
}
