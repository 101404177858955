@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/breakpoints';
@use 'styles/spacing';

$overlap-tablet: 20%;
$overlap-tablet-landscape: 15%;
$overlap-desktop: 10%;
$overlap-desktop-xl: 5%;

.root {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;

  // Overlap
  @include breakpoints.media(tablet) {
    margin-bottom: -$overlap-tablet;
  }
  @include breakpoints.media(tablet-landscape) {
    margin-bottom: -$overlap-tablet-landscape;
  }
  @include breakpoints.media(desktop) {
    margin-bottom: -$overlap-desktop;
  }
  @include breakpoints.media(desktop-xl) {
    margin-bottom: -$overlap-desktop-xl;
  }
}

.rootWithNoSquareImage {
  aspect-ratio: 375 / 360;

  @include breakpoints.media(mobile-landscape) {
    aspect-ratio: 600 / 336;
  }

  @include breakpoints.media(tablet) {
    aspect-ratio: 769 / 431;
  }

  @include breakpoints.media(tablet-landscape) {
    aspect-ratio: 962 / 549;
  }

  @include breakpoints.media(desktop-small) {
    aspect-ratio: 1024 / 534;
  }

  @include breakpoints.media(desktop) {
    aspect-ratio: 1440 / 647;
  }

  @include breakpoints.media(desktop-xl) {
    aspect-ratio: 3823 / 1441;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: spacing.$spacing-24;
  padding-bottom: 2%;
  position: relative;

  .rootWithSquareImage & {
    padding-top: spacing.$spacing-16;
    padding-bottom: spacing.$spacing-16;

    @include breakpoints.media(mobile-landscape) {
      padding-top: spacing.$spacing-24;
    }

    @include breakpoints.media(tablet) {
      padding-top: spacing.$spacing-48;
    }

    @include breakpoints.media(tablet-landscape) {
      padding-top: spacing.$spacing-80;
      padding-bottom: spacing.$spacing-40;
    }
  }
}

.overlap {
  grid-column: 1 / 2;
  grid-row: 2 / 3;

  @include breakpoints.media(tablet) {
    margin-bottom: $overlap-tablet;
  }
  @include breakpoints.media(tablet-landscape) {
    margin-bottom: $overlap-tablet-landscape;
  }
  @include breakpoints.media(desktop) {
    margin-bottom: $overlap-desktop;
  }
  @include breakpoints.media(desktop-xl) {
    margin-bottom: $overlap-desktop-xl;
  }
}

%placeholder {
  background-color: $yds-color-background-interactive;
  max-width: 100%;
  animation: 1s pulse infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.999;
  }
  50% {
    opacity: 0.8;
  }
}

.image {
  @extend %placeholder;
  aspect-ratio: 1 / 1;
  margin-bottom: spacing.$spacing-16;

  @include breakpoints.media(mobile-landscape) {
    margin-bottom: 0;
  }
}

.textContent {
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: flex-end;

  .rootWithSquareImage & {
    @include breakpoints.media(tablet) {
      margin-left: spacing.$spacing-16;
    }
  }
}

.highlightLabel {
  @extend %placeholder;
  width: 5rem;
  height: 1.375rem;
  margin-bottom: spacing.$spacing-8;
  border-radius: 4px;
  animation-delay: 0.2s;
}

.title {
  @extend %placeholder;
  width: 18rem;
  height: 30px;
  margin-bottom: spacing.$spacing-8;
  animation-delay: 0.4s;
}

.description {
  @extend %placeholder;
  width: 24rem;
  height: 1.5rem;
  margin-bottom: spacing.$spacing-16;
  animation-delay: 0.6s;
}

.control {
  @extend %placeholder;
  width: 8rem;
  height: 40px;
  border-radius: 999px;
  animation-delay: 0.8s;
}
