@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;

.button {
  background: none;
  border: none;
  color: $yds-color-text-default;
  display: block;
  height: 44px;
  padding: 0;
  position: relative;
  width: 44px;
  transition: color 0.2s;

  &:hover {
    color: $yds-color-text-negative;
  }

  &[hidden] {
    display: none;

    &:hover {
      display: block;
      opacity: 0;
    }
  }

  &Left {
    transform: scaleX(-1);
  }

  &::before {
    background-color: $yds-color-gray-60;
    border-radius: 100px;
    content: '';
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.91);
    width: 100%;
    transition: all 0.2s;
  }
  &:hover::before {
    background-color: $yds-color-action-primary;
    transform: translate(-50%, -50%);
  }
}

.icon {
  height: 27px;
  left: 53%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.91);
  width: 27px;
}
.button:hover .icon {
  transform: translate(-50%, -50%);
}
