@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/spacing';
@use 'styles/typography';
@use 'styles/breakpoints';

.header {
  align-items: flex-end;
  display: flex;
  margin-bottom: spacing.$spacing-16;
}

.title {
  flex-grow: 1;
  margin: 0;

  @include typography.default-xl-bold;

  @include breakpoints.media(mobile-landscape) {
    flex-grow: 0;
  }
}

.controls {
  align-items: center;
  display: flex;
  flex: none;
  margin-left: spacing.$spacing-16;
  min-height: 1.5rem;
  position: relative;
  bottom: 0.125rem;

  @include breakpoints.media(mobile-landscape) {
    border-left: 2px solid $yds-color-border-separator;
    padding-left: spacing.$spacing-16;
  }
}
