@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/typography';
@use 'styles/spacing';
@use 'styles/cardStyles';
@use 'styles/breakpoints';

.root {
  color: $yds-color-text-default;
  position: relative;
}

.dropdown {
  appearance: none;
  background-color: transparent;
  border-radius: $yds-border-radius-medium;
  border: 1px solid $yds-color-action-primary-variant;
  color: inherit;
  color-scheme: normal;
  overflow: hidden;
  padding-bottom: spacing.$spacing-8;
  padding-left: spacing.$spacing-16;
  padding-right: 2.125rem; // to avoid long titles to clip with the icon
  padding-top: spacing.$spacing-8;
  text-overflow: ellipsis;
  width: 100%;

  @include typography.default-m-bold;

  option {
    background-color: initial;
    color: initial;
  }
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  pointer-events: none;
  position: absolute;
  right: spacing.$spacing-8;
  top: 50%;
  transform: translateY(-50%);
}
