@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/typography';
@use 'styles/spacing';

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: spacing.$spacing-6;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba($yds-color-black, 0.5);
  color: $yds-color-text-default;
  @include typography.default-m;
}
