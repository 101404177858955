@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/breakpoints';
@use 'styles/cardStyles';

.placeholderCard {
  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
  padding-bottom: 5.375rem;

  @for $i from 2 through 10 {
    :nth-child(#{$i}) > & {
      animation-delay: ($i - 1) * 0.3s;
    }
  }

  &Vertical {
    padding-bottom: 0;
    @include cardStyles.cardWidthVertical;
  }
  &VerticalWithAspectRatio_1_1 {
    padding-bottom: 0;
    @include cardStyles.cardWidthVerticalWithAspectRatio_1_1;
  }

  &::after {
    background-color: $yds-color-background-interactive;
    content: '';
    display: block;
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.999;
  }
  50% {
    opacity: 0.8;
  }
}

.aspectRatio_1_1::after {
  padding-top: 100%; // =1/(1/1)
}
.aspectRatio_16_9::after {
  padding-top: 56.25%; // =1/(16/9)
}
.aspectRatio_9_13::after {
  padding-top: 144.44%; // ≈1/(9/13)
}
