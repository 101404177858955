@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';

$overlap-tablet: 20%;
$overlap-tablet-landscape: 15%;
$overlap-desktop: 10%;
$overlap-desktop-xl: 5%;

.root {
  display: grid;
  grid-template-areas:
    'content'
    'overlap';
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;

  // Overlap
  @include breakpoints.media(tablet) {
    margin-bottom: -$overlap-tablet;
  }
  @include breakpoints.media(tablet-landscape) {
    margin-bottom: -$overlap-tablet-landscape;
  }
  @include breakpoints.media(desktop) {
    margin-bottom: -$overlap-desktop;
  }
  @include breakpoints.media(desktop-xl) {
    margin-bottom: -$overlap-desktop-xl;
  }
}

.backgroundImageWrapper {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  position: relative;
  z-index: -1;

  .rootWithNoSquareImage & {
    aspect-ratio: 375 / 360; // ≈ 1.04

    @include breakpoints.media(mobile-landscape) {
      aspect-ratio: 600 / 336; // = ≈ 1.79
    }

    @include breakpoints.media(tablet) {
      aspect-ratio: 769 / 431; // ≈ 1.78
    }

    @include breakpoints.media(tablet-landscape) {
      aspect-ratio: 962 / 549; // ≈ 1.75
    }

    @include breakpoints.media(desktop-small) {
      aspect-ratio: 1024 / 534; // ≈ 1.92
    }

    @include breakpoints.media(desktop) {
      aspect-ratio: 1440 / 647; // ≈ 2.22
    }

    @include breakpoints.media(desktop-xl) {
      aspect-ratio: 3823 / 1441; // ≈ 2.65
    }
  }

  // Gradient overlay
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background:
      linear-gradient(
        180deg,
        rgba($yds-color-background, 0) 83.91%,
        $yds-color-background 100%
      ),
      linear-gradient(
        244deg,
        rgba($yds-color-background, 0) 12.55%,
        rgba($yds-color-background, 0.8) 74.64%
      );

    @include breakpoints.media(mobile-landscape) {
      background:
        linear-gradient(
          180deg,
          rgba($yds-color-background, 0) 83.91%,
          $yds-color-background 100%
        ),
        linear-gradient(
          256deg,
          rgba($yds-color-background, 0) 28.86%,
          rgba($yds-color-background, 0.8) 75.83%
        );
    }

    .rootWithSquareImage & {
      background: linear-gradient(
        180deg,
        rgba($yds-color-background, 0.8) 40%,
        $yds-color-background 100%
      );
    }
  }
}

.backgroundImage {
  object-fit: cover;
  object-position: center top;
  opacity: 0;
  transition: opacity 0.8s;

  &Loaded {
    opacity: 1;
  }
}

.overlap {
  grid-area: overlap;

  @include breakpoints.media(tablet) {
    margin-bottom: $overlap-tablet;
  }
  @include breakpoints.media(tablet-landscape) {
    margin-bottom: $overlap-tablet-landscape;
  }
  @include breakpoints.media(desktop) {
    margin-bottom: $overlap-desktop;
  }
  @include breakpoints.media(desktop-xl) {
    margin-bottom: $overlap-desktop-xl;
  }
}

.content {
  display: flex;
  flex-direction: column;
  grid-area: content;
  justify-content: flex-end;
  padding-top: spacing.$spacing-24;
  padding-bottom: 2%;
  position: relative;

  .rootWithSquareImage & {
    padding-top: spacing.$spacing-16;
    padding-bottom: spacing.$spacing-16;

    @include breakpoints.media(mobile-landscape) {
      padding-top: spacing.$spacing-24;
    }

    @include breakpoints.media(tablet) {
      padding-top: spacing.$spacing-48;
    }

    @include breakpoints.media(tablet-landscape) {
      padding-top: spacing.$spacing-80;
      padding-bottom: spacing.$spacing-40;
    }
  }
}

.image {
  aspect-ratio: 1 / 1;
  margin-bottom: spacing.$spacing-16;
  position: relative;

  @include breakpoints.media(mobile-landscape) {
    margin-bottom: 0;
  }
}

.textContent {
  display: flex;
  max-width: 618px;
  min-height: 100%;
  flex-direction: column;
  justify-content: flex-end;

  .rootWithSquareImage & {
    @include breakpoints.media(tablet) {
      margin-left: spacing.$spacing-16;
    }
  }
}

.logo {
  margin-bottom: spacing.$spacing-16;
}

.highlightLabels {
  margin-bottom: spacing.$spacing-8;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  text-wrap: balance;

  @include typography.default-2xl-heavy;

  @include breakpoints.media(mobile-landscape) {
    @include typography.default-4xl-heavy;
  }
  @include breakpoints.media(tablet-landscape) {
    @include typography.default-5xl-heavy;
  }

  .logo ~ & {
    @include typography.default-xl-heavy;

    @include breakpoints.media(mobile-landscape) {
      @include typography.default-xl-heavy;
    }
    @include breakpoints.media(tablet-landscape) {
      @include typography.default-xl-heavy;
    }
  }
}

.link {
  &::after {
    content: '';
    position: absolute;
    inset: 0;
  }
}
a.link {
  text-decoration: none;
}
button.link {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  margin: 0;
  padding: 0;
}

.description {
  @include typography.default-m;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  margin-top: spacing.$spacing-8;
  margin-bottom: 0;
}

.controls {
  /**
    Needs to be positioned because interactive elements have to
    be lifted above the link overlay
  */
  position: relative;

  margin-top: spacing.$spacing-16;
}
