@use 'styles/breakpoints';
@use 'styles/spacing';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: spacing.$spacing-8;
  max-width: 998px;

  @include breakpoints.media(tablet) {
    gap: spacing.$spacing-16;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: spacing.$spacing-8;
  padding: 0;
  list-style: none;
  margin: 0;

  @include breakpoints.media(tablet) {
    gap: spacing.$spacing-16;
  }
}
