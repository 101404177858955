.listNotifications {
  display: inline-block;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;

  &.text {
    margin-top: 0;
  }
}
