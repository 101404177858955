@use 'styles/spacing';

.playButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: block;
  position: relative;
  padding: 0;
  transition: opacity 0.2s;

  &AsOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &Disabled {
    opacity: 0.5;
  }
}

.icon {
  width: 2.75rem;
  height: 2.75rem;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }

  .playButtonAsOverlay & {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .playButtonAsOverlay:hover & {
    transform: translate(-50%, -50%) scale(1.1);
  }
}
