@use 'styles/breakpoints';

.mobile {
  display: block;
  @include breakpoints.media(tablet) {
    display: none;
  }
}
.desktop {
  display: none;
  @include breakpoints.media(tablet) {
    display: block;
  }
}
