@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 96px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(26, 27, 28, 0) 0%,
    rgba(26, 27, 28, 0.6) 100%
  );
}
