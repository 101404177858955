@use 'styles/breakpoints';
@use 'styles/spacing';

.list {
  margin-bottom: spacing.$spacing-24;

  @include breakpoints.media(tablet) {
    margin-bottom: spacing.$spacing-32;
  }
  @include breakpoints.media(desktop-small) {
    margin-bottom: spacing.$spacing-40;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
