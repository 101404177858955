@use 'styles/breakpoints';

.logo {
  display: block;
  object-fit: contain;
  object-position: left;
  width: 161px;
  height: 91px;

  @include breakpoints.media(tablet-landscape) {
    width: 205px;
    height: 116px;
  }

  @include breakpoints.media(desktop-small) {
    width: 251px;
    height: 142px;
  }
}
