@use 'styles/spacing';
@use 'styles/breakpoints';

.filters {
  display: flex;
  flex-direction: column;
  gap: spacing.$spacing-8;

  @include breakpoints.media(tablet) {
    max-width: 240px;
  }
}
