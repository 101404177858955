@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/spacing';

.removeButton {
  border: none;
  background-color: transparent;
  color: $yds-color-action-primary;
  cursor: pointer;
  display: block;
  position: relative;
  padding: 0;
  transition: opacity 0.2s;

  &AsOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &Disabled {
    opacity: 0.5;
  }
}

.icon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  fill: currentColor;
}
