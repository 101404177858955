@use 'sass:map';
@use 'sass:math';
@use 'styles/breakpoints';

$listItemWidths: (
  default: (
    default: 43.32%,
    mobile-landscape: 28.51%,
    tablet: 25%,
    tablet-landscape: 20%,
    desktop: 16.66%,
  ),
  landscape: (
    default: 66.66%,
    mobile-landscape: 45.83%,
    tablet: 33.33%,
    desktop-small: 25%,
  ),
);

@mixin scrollButtonMarginTop($ratio) {
  $hasLandscapeImages: $ratio > 1;
  $widths: map.get(
    $listItemWidths,
    if($hasLandscapeImages, landscape, default)
  );

  @each $breakpoint, $width in $widths {
    @if $breakpoint == default {
      margin-top: math.div($width, $ratio) * 0.5;
    } @else {
      @include breakpoints.media($breakpoint) {
        margin-top: math.div($width, $ratio) * 0.5;
      }
    }
  }
}

$smallGutter: 16px;
$largeGutter: 24px;

.root {
  position: relative;
}

.scrollContainer {
  overflow: hidden;
  overflow-x: auto;

  /* Hide scroll bar */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.scrollButtonsOuterWrapper {
  position: absolute;
  top: 0;
  width: 100%;
}
.scrollButtonsInnerWrapper {
  position: relative;
}

.scrollButton {
  opacity: 0;
  position: absolute;
  transform: translateY(-50%);
  transition: all 0.2s;
  visibility: hidden;

  .root:hover & {
    opacity: 1;
    visibility: visible;

    @media (hover: none) {
      visibility: hidden;
    }
  }

  .aspectRatio_1_1 & {
    @include scrollButtonMarginTop(math.div(1, 1));
  }
  .aspectRatio_9_13 & {
    @include scrollButtonMarginTop(math.div(9, 13));
  }
  .aspectRatio_16_9 & {
    @include scrollButtonMarginTop(math.div(16, 9));
  }

  &Left {
    left: 0;
  }
  &Right {
    right: 0;
  }
}

.list {
  display: flex;
  margin: 0;
  margin-right: -$smallGutter;
  padding: 0;

  @include breakpoints.media(desktop-small) {
    margin-right: -$largeGutter;
  }

  &::after {
    content: '';
    display: block;
    flex: none;
    width: calc(5vw - $smallGutter);

    @include breakpoints.media(desktop-small) {
      width: calc(5vw - $largeGutter);
    }
  }
}

@mixin listItemWidths($imageType) {
  $widths: map.get($listItemWidths, $imageType);

  @each $breakpoint, $width in $widths {
    @if $breakpoint == default {
      width: $width;
    } @else {
      @include breakpoints.media($breakpoint) {
        width: $width;
      }
    }
  }
}

.listItem {
  display: block;
  flex: none;
  padding-right: $smallGutter;

  @include listItemWidths(default);

  @include breakpoints.media(desktop-small) {
    padding-right: $largeGutter;
  }

  .aspectRatio_16_9 & {
    @include listItemWidths(landscape);
  }
}
